import { useTheme } from "../contexts/ThemeContext";
import { Heading, SubHeading, PText, HighlightedLink } from "../styles/text";
import FadeInSection from "../ui/FadeInSection";
import styled from "styled-components";
import {
  SplitContainer,
  MainRow,
  SubRow,
  ResumeDetails,
  BulletWrapper,
  TextWrapper,
} from "../styles/divs";
import Pill from "../components/Pill";
import { BsThreeDotsVertical } from "react-icons/bs";
import { PiDotFill } from "react-icons/pi";

let ResumeContent = [
  {
    company_name: "Amazon.com",
    location: "Seattle, WA",
    position: "Software Development Engineer",
    date: "April 2024 - Present",
    skills: ["Java", "AWS", "Spring", "React", "Redux", "CI/CD", "Dagger"],
    details: [
      "Drove 17% worldwide customer adoption of Amazon Business's Pay By Invoice service within two weeks of launching an event-based notification system using AWS Lambda, SQS, and SNS with AWS CDK",
      "Led a joint team migration responsible for handling 1.2 million monthly email notifications by acting as the main point of contact for migrating and service-owning teams, ensuring a seamless transition with zero outages",
      "Instituted and organized team hackathons, team events, and knowledge transfer sessions to develop tools that generate on-call reports, foster collaboration, and build stronger interpersonal relationships for long-term productivity",
      "Secured vulnerable endpoints by implementing a reusable Spring annotation to validate incoming requests, reducing the risk of unauthorized access",
    ],
    color: "orange",
    link: "https://www.amazon.com/",
    roles: [
      {
        title: "Software Development Engineering Intern",
        date: "June 2023 - August 2023",
        details: [
          "Migrated Amazon Business’s “Pay By Invoice” service onto Native AWS using ECS clusters to enable CloudWatch Container Insights for enhanced application monitoring and autoscaling capabilities",
          "Reengineered the Pay By Invoice onboarding page UI by introducing proper user input validation and developing reusable components with React hooks and Redux, resolving reoccurring ticket issues",
        ],
      },
    ],
  },
  {
    company_name: "Capacity Management Intl Inc.",
    location: "Dennis Port, MA",
    position: "Software Engineer",
    date: "December 2023 – April 2024",
    skills: ["React", "Node.js", "Express.js", "MongoDB", "AWS S3"],
    details: [
      "Increase revenue by 10% per booking by developing a MERN stack vacation property website from scratch with TypeScript, AWS Lambda/S3, and JWT authentication to eliminate reliance on third-party services",
      "Boosted return customer bookings by 25% by integrating QR codes linking to the website into all property handbooks",
      "Led a strategic marketing campaign in collaboration with property owners, leveraging a mailing list to successfully promote the new web application to previous / perspective guests, boosting property exposure",
    ],
    link: "https://www.capeoceanvista.com/",
    color: "#5D8A96",
  },
  {
    company_name: "Casa Systems",
    location: "Andover, MA",
    position: "Full Stack Software Developer Intern",
    date: "June 2022 - August 2022",
    skills: ["Python", "Flask", "JavaScript", "R"],
    details: [
      "Automated CMTS (Cable Modem Termination System) monitoring scripts using Python and SSHv2 API protocols",
      "Eliminated reliance on third-party services by developing a web application using Flask, JavaScript, and Python to centralize all CMTS monitoring data from 5 customer companies",
      "Analyzed CMTS monitoring data by automating R scripts to compare hardware performance after customer upgrades",
    ],
    color: "#F5821E",
    link: "https://www.casa-systems.com/",
  },
  {
    company_name: "Lily Lab at Yale",
    location: "New Haven, CT",
    position: "Research Intern",
    date: "Janurary - May 2022",
    skills: ["Python", "NLP", "Web Scraping", "BeautifulSoup"],
    details: [
      "Improved an NLP search engine’s accuracy by 28% by implementing a text crawler with Python/BeautifulSoup to prefill a database with key page metadata used in querying",
      "Tested the NLP search engine by implementing a bot to search a random set of keywords to show a reduction of 'no results found' outcomes",
    ],
    color: "#01B2DE",
    link: "https://yale-lily.github.io/",
  },
  {
    company_name: "DreamKit",
    location: "New Haven, CT",
    position: "Web Design/Marketing Intern",
    date: "Janurary - May 2022",
    details: [
      "Implemented interactive blogging features on DreamKit’s homepage to promote community engagement by using Squarespace’s platform",
      "Pushed to create several partnerships by calling existing non-profit organizations in New Haven to expand the reach of DreamKit’s platform",
    ],
    color: "#7142ED",
    link: "https://www.dreamkitapp.com/",
  },
];

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

function ExperienceContent() {
  const { theme } = useTheme();

  function getCompanyNameComponent(
    company_name: string,
    link: string,
    color: string
  ) {
    if (company_name === "") {
      return <BsThreeDotsVertical color={color} size="1.5rem" />;
    }

    if (link !== "") {
      return (
        <HighlightedLink color={color} href={link} aria-disabled>
          <Heading>{company_name}</Heading>
        </HighlightedLink>
      );
    }
    return <Heading>{company_name}</Heading>;
  }

  return (
    <ContentDiv>
      {ResumeContent.map((job, index) => (
        <div>
          <FadeInSection key={index}>
            {/* <div key={index}> */}
            <SplitContainer>
              <MainRow>
                {/* {job.link ? (
                <HighlightedLink
                  color={job.color}
                  href={job.link}
                  aria-disabled
                >
                  <Heading>{job.company_name}</Heading>
                </HighlightedLink>
              ) : (
                  <Heading>{job.company_name}</Heading> 
              )}
              {/* <HighlightedLink color={job.color} href={job.link} aria-disabled>
                <Heading>{job.company_name}</Heading>
              </HighlightedLink> */}
                {getCompanyNameComponent(job.company_name, job.link, job.color)}
                <Heading>{job.location}</Heading>
              </MainRow>
              <SubRow>
                <SubHeading mode={theme}>{job.position}</SubHeading>
                <SubHeading mode={theme}>{job.date}</SubHeading>
              </SubRow>
            </SplitContainer>
            <ResumeDetails style={{ justifyContent: "flex-start" }}>
              {job.skills?.map((skill, index) => (
                <Pill key={index} borderColor={job.color} textColor={job.color}>
                  {skill}
                </Pill>
              ))}
            </ResumeDetails>
            {job.details.map((detail, index) => (
              <ResumeDetails>
                <BulletWrapper>
                  <PiDotFill />
                </BulletWrapper>
                <TextWrapper>
                  <PText mode={theme}>{detail}</PText>
                </TextWrapper>
              </ResumeDetails>
            ))}
            {/* <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "1rem",
            }}
          >
            <MdVerticalDistribute color={job.color} size="2rem" />
          </div> */}
            {/* <MdVerticalDistribute color={job.color} size="1.5rem" /> */}
          </FadeInSection>
          {job.roles?.map((role, index) => (
            <FadeInSection>
              <div style={{ paddingTop: "1.5rem" }}>
                <SubRow>
                  <SubHeading mode={theme}>{role.title}</SubHeading>
                  <SubHeading mode={theme}>{role.date}</SubHeading>
                </SubRow>
                {role.details.map((detail, index) => (
                  <ResumeDetails>
                    <BulletWrapper>
                      <PiDotFill />
                    </BulletWrapper>
                    <TextWrapper>
                      <PText mode={theme}>{detail}</PText>
                    </TextWrapper>
                  </ResumeDetails>
                ))}
              </div>
            </FadeInSection>
          ))}
          <div style={{ marginBottom: "2rem" }}></div>
        </div>
      ))}
    </ContentDiv>
  );
}

export default ExperienceContent;
